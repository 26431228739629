import axios from 'axios';
// import { Message } from 'iview';
// import Vue from 'vue';
// Vue.prototype.$Message = Message;
axios.defaults.baseURL = '/api/v1';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.transformRequest = [function (data) {
    return typeof data === 'object' ? JSON.stringify(data) : data;
}];

const CODE_MAP = {
    UNLOGIN: 600,
    SUCCESS: 200
};

export function post(url, data, config) {
    return axios.post(url, data, config).then(dealApi, serverError);
}

export function get(url, data) {
    return axios.get(url, {params: data}).then(dealApi, serverError);
}

export function getQuery(name) {
    const search = location.search;
    if (!name || !search) {
        return;
    }
    const result = search.match(new RegExp(`[?&]${name}=([^&]+)`, 'i'));
    if (result !== null && result.length > 0) {
        // eslint-disable-next-line consistent-return
        return result[1];
    }
}
let preview = getQuery('preview');
let source = getQuery('source');
let lang = getQuery('language');
if(lang){
    localStorage.setItem('language', lang);
}
// import qs from 'qs';
// import axios from 'axios';

// 请求的拦截器
axios.interceptors.request.use(function (config) {
    let language =  localStorage.getItem('language')||'cn';
    config.headers['language'] = language;
    config.data = {
        ...config.data,
        preview,
        source,
    };
    return config;
}, function (error) {
    return Promise.reject(error);
});

// axios.interceptors.request.use(function (config) {
//     config.data = {
//         ...config.data,
//         preview,
//         source,
//     };
// });
function dealApi(res) {
    let data = res.data;
    if (data.code === CODE_MAP.SUCCESS) {
        return data.result;
    } 
    // Message.error(data.msg);
    throw new Error(data.msg);
}

function serverError(res) {
    let msg = res.message;
    // Message.error('服务器异常，状态码：'+msg);
    throw new Error('服务器异常，状态码：'+msg);
}


export default {
    axios,
    post,
    get,
};

<template>
    <div class="recruit-detail-page text-left">
        <div class="recruit-header">
            <div class="recruit-title">
                {{recruitInfo.title}}
            </div>
            <div class="recruit-city">
                {{recruitInfo.city_name}}
            </div>
            <div class="white-line-decorate">

            </div>
        </div>
        <div class="recruit-detail padding ">
            <div class="detail-item">
                <div class="item-header-title">
                    <div class="line">

                    </div>
                    {{$dataConfig.jobs}}
                </div>
                <div class="job-events" v-html="recruitInfo.desc">
                </div>
            </div>
            <div class="detail-item">
                <div class="item-header-title">
                    <div class="line">
                    </div>
                    {{$dataConfig.jobAsks}}
                </div>
                <div class="job-events" v-html="recruitInfo.desc">
                    {{recruitInfo.require}}
                </div>
            </div>
            <div class="detail-item">
                <div class="item-header-title">
                    <div class="line">
                    </div>
                    {{$dataConfig.linkMethods}}
                </div>
                <div class="job-events">
                    <div>{{$dataConfig.jobDesc}}: </div>
                    {{recruitInfo.contact_email}}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import IndexApi from '../api/index';

export default {
    data(){
        return{
            recruitInfo: {}
        };
    },
    created(){
        this.getProductDetail();
    },
    methods: {
        async getProductDetail(){
            const {id} = this.$route.params;
            let data = await  IndexApi.recruitDetail({id});
            this.recruitInfo = data;
            console.log('productDetail', data);
        }
    }
};
</script>
<style lang="less" scoped>
.recruit-detail-page{
    .recruit-header{
        height: 506px;
        background: #303C5B;
        padding: 182px 2.8rem 0;
    }
    .recruit-title {
        font-size: 48px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 67px;
        letter-spacing: 13px;
    }
    .recruit-city {
        font-size: 28px;
        //
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        letter-spacing: 4px;
        margin-top: 24px;
        margin-bottom: 1.14rem;
    }
    .recruit-detail{
        padding-top: 40px;
        border-top: 1px solid #E5EDF1;;
    }
    .detail-item {
        margin-bottom: 40px;
    }
    .item-header-title {
        height: 44px;
        line-height: 44px;
        background: #F5F8FB;
        font-size: 24px;
        //
        font-weight: 400;
        color: #30425A;
        line-height: 32px;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        .line{
            width: 3px;
            height: 24px;
            background: #30425A;
            margin: 0 16px;
        }
    }
    .job-events {
        font-size: 16px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 40px;
        letter-spacing: 1px;
        margin-top: 36px;
    }
    @media all and (max-width: 1017px) {
        .recruit-header{
            background: #303C5B;
            height: auto;
            padding: 3.46rem 1.2rem 0.56rem  1.2rem;
            padding-top: 85px;
        }
        .recruit-title {
            font-size: 44px;
        }
    }
}
</style>
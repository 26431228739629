<template>
    <div class="video-play" ref="com">
        <video
            class="video"
            v-show="platButtonStatus==='play'||!haveNoClick"
            ref="video"
            preload="auto"
            playsinline="true"
            webkit-playsinline="true"
            x5-playsinline="true"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            x5-video-player-fullscreen="true"
            controls
        >
            <source v-for="(item,index) in videoUrl" :src="item.url" :key="index" />
        </video>
        <div
            class="poster flex flex-center"
            v-show="platButtonStatus==='paused'&&haveNoClick"
            @click="onPlayClick"
        >
            <div class="logo-img-bg"></div>
        </div>
        <div class="playButtonWrapper" v-if="playIconUrl" v-show="platButtonStatus==='paused'">
            <div
                class="playButton"
                :style="{backgroundImage: `url(${playIconUrl})`}"
                @click="onPlayClick"
            ></div>
        </div>
        <slot name="floatEles"></slot>
    </div>
</template>
<script>

export default {
    name: 'video-play',
    props: {
        isInApp: {
            type: Boolean,
            default: false,
        },
        startTime: {
            type: Number,
            default: 0,
        },
        cycleAll: {
            type: Boolean,
            default: false,
        },
        needCycle: {
            type: Boolean,
            default: false,
        },
        videoUrl: {
            type: Array,
            required: true,
        },
        playIconUrl: {
            type: String,
            required: false,
            default: '',
        },
        posterUrl: {
            type: String,
            default: '',
        },
        autoPlay: {
            type: Boolean,
            required: false,
            default: false,
        },
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            haveNoClick: true,
            platButtonStatus: 'paused', // play or paused,
            currentTime: null,
        };
    },
    mounted() {
        const video = this.$refs.video;
        if (this.needCycle) {
            this.cycleVideo(video);
        }
        this.$emit('get-video', video);
        video.oncanplay = () => {
            if (this.$props.autoPlay) {
                video.play();
            }
        };
        video.onended = () => {
            this.$data.platButtonStatus = 'paused';
        };
        video.onplay = () => {
            this.$data.platButtonStatus = 'play';
        };
        video.onpause = () => {
            this.$data.platButtonStatus = 'paused';
        };
        // 从h5切到其他页面视频会中断，故app切回重新播放
        // ksBridge.on({
        //     type: 'native_reentry',
        //     handler: () => {
        //         const video = this.$refs.video;
        //         if (video.paused) {
        //             video.play();
        //         }
        //     },
        // });
    },
    destroyed() {
        // ksBridge.off({
        //     type: 'native_reentry',
        // });
    },
    methods: {
        cycleVideo(video) {
            // 解决是否是ios省电模式下
            setTimeout(() => {
                if (!this.currentTime) {
                    this.$emit('on-time', 7 );
                }
            }, 1000);
            video.addEventListener('timeupdate', () => {
                this.currentTime = video.currentTime;
                if (video.currentTime >= video.duration - 0.3) {
                    video.currentTime = this.startTime;
                }
            });
        },
        onPlayClick() {
            this.haveNoClick = false;
            const video = this.$refs.video;
            if (video.paused) {
                video.play();
                this.$emit('play-click', false);
            } else {
                video.pause();
                this.$emit('play-click', true);

            }
            if (this.onClick) {
                this.onClick();
            }
        },
    },
};
</script>
<style lang='less' scoped>
.video-play {
    width: 100%;
    padding-top: 56%;
    position: relative;
    // video::-webkit-media-controls-enclosure {
    //     display: none;
    // }
    .logo-img-bg{
        display: inline-block;
        width: 149px;
        height: 33px;
        position: relative;
        background: url(../assets/lxlogo-white.png) no-repeat 0% 0% / contain !important;
    }
    .video {
        width: 100%;
        height: 100%;
        background-color: black;
        position: absolute;
        left: 0;
        top: 0;
    }
    .poster {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .playButtonWrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rgba(0, 0, 0, 0.5);
        .playButton {
            width: 50px;
            height: 50px;
            background-size: contain;
        }
    }
}
</style>
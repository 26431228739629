<template>
    <div class="recruit-page">
        <!-- 招聘 -->
        <div class="banner-img flex">
            <div class="left-news-descrip">
                <div class="first-title">
                    TEAM
                </div>
                <div class="second-title">
                    {{$dataConfig.companyName}}
                </div>
                <div class="top-news-content">
                    {{$dataConfig.team}}
                </div>
                <div class="margin-top114 white-line-decorate">
                    
                </div>
            </div>
            <div class="right-top-img">
                <img :src="require('../assets/Rectangle@2x.png')" alt="">
            </div>
        </div>
        <div class="boss-area flex flex-between">
            <div class="boss-item">
                <div class="flex align-center justify-center  text-desc">
                    {{$dataConfig.limit}}<br/>  {{$dataConfig.magic}}
                </div>
                <div class="item-mask" 
                     :style="{background:`url(${require('../assets/boss1@2x.png')}) no-repeat 100% 100% / cover`}">
                   
                </div>
            </div>
            <div class="boss-line"></div>
            <div class="boss-item">
                <div class="flex align-center  justify-center  text-desc">
                    {{$dataConfig.details}}<br/> {{$dataConfig.perfect}}
                </div>
                <div class="item-mask" 
                     :style="{background:`url(${require('../assets/boss2@2x.png')}) no-repeat 100% 100% / cover`}">
                </div>
            </div>
            <div class="boss-line"></div>
            <div class="boss-item">
                <div class="flex align-center justify-center  text-desc">
                    {{$dataConfig.react}} <br/>{{$dataConfig.young}}
                </div>
                <div class="item-mask" 
                     :style="{background:`url(${require('../assets/boss3@2x.png')}) no-repeat 100% 100% / cover`}">
                </div>
            </div>
        </div>
        <div class="open-jobs flex flex-between">
            <div class="left-area">
                <div class="line-decorate">
                </div>
                <div class="art-font-header">
                    SPECIFICATIONS
                </div>
                <div class="open-jobs-title">
                    {{$dataConfig.openJob}}
                </div>
                <div class="join-us">
                    {{$dataConfig.join}}
                </div>
            </div>
            
            <div class="right-area flex1">
                <div class="city-select flex">
                    <div class="city-item"
                         @click="slectCity(index)"
                         v-for="item,index in recruitData" :key="index"
                         :class="{'city-active':currentIndex==index}"
                    >
                        {{item.city_name}}
                    </div>
                </div>
                <div class="job-list align-center flex" v-if="recruitData.length">
                    <div class="job-item flex-wrap" @click="viewDetail(elem.id)" v-for="elem,i in recruitData[currentIndex].recruit" :key="i">
                        <div class="job-title"> {{elem.title}}</div>
                        <div class="job-count">{{elem.count}}{{elem.count>1? $dataConfig.counts:$dataConfig.count}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding company-life flex flex-between">
            <div class="left-area">
                <div class="line-decorate">
                </div>
                <div class="life-title">
                    SPECIFICATIONS
                </div>
                <div class="company-life-title">
                    {{$dataConfig.life}}
                </div>
                <div class="life-description">
                    {{$dataConfig.lifeDesc}}
                </div>
            </div>
            <div class="right-area-life">
                <img src="../assets/group_life@2x.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
// import { Button } from 'ant-design-vue';

import IndexApi from '../api/index';
export default {
    components: {
        // [Button.name]: Button
    },
    data() {
        return {
            theme2: 'light',
            recruitData: [],
            cityList: [],
            currentIndex: 0
        };
    },
    created(){
        this.initData();
    },
    methods: {
        async initData() {
            let data = await  IndexApi.recruit();
            const {list} = data;
            const all = [];
            list.forEach((item) => {
                all.push(...item.recruit);
            });
            let obj = {
                city_name: this.$dataConfig.all,
                recruit: all
            };
            list.unshift(obj);
            this.recruitData =list ;
            this.cityList =  list.map((item) => {
                return item.city_name;
            });    
        },
        slectCity(index){
            this.currentIndex = index;
        },
        viewDetail(id){
            this.$router.push({
                name: 'recruit-detail',
                params: {
                    id
                }
            });
        }

    }
};
</script>
<style lang="less" scoped>
.recruit-page {
    .city-item {
        display: flex;
        width: 68px;
        height: 27px;
        line-height: 27px;
        font-size: 18px;
        //
        font-weight: 400;
        color: #2F353D;
        justify-content: center;
        margin-right: 32px;
        cursor: pointer;
    }
    .job-list{
        width: 9.03rem;
        flex-wrap: wrap;
        margin-top: 44px;
        padding-top: 32px;
        border-top: 1px solid #E5EDF1;;
    }
    .job-title {
        font-size: 18px;
        //
        font-weight: 400;
        color: #2F353D;
        width: 100%;
    }
    .job-count{
        font-size: 14px;
        //
        font-weight: 400;
        color: #2F353D;
        margin-top: 8px;
    }
    .open-jobs-title {
        // width: 128px;
        height: 40px;
        font-size: 28px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 40px;
        letter-spacing: 4px;
        margin-top: 45px;
    }
    .city-active{
        background: #303C5B;
        border-radius: 14px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }
    .join-us {
        font-size: 16px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 32px;
        letter-spacing: 2px;
        // white-space: nowrap;
    }
    
    .job-item{
        width: 252px;
        // height: 90px;
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid #E4E7EB;
        margin-right: 32px;
        margin-bottom: 32px;
        font-size: .18rem;
        //
        font-weight: 400;
        color: #2F353D;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            box-shadow: 0px 0px 20px 0px rgba(48, 66, 90, 0.1);
            border: 1px solid #E5EDF1;
        }
    }

    .boss-area {
        margin: 100px 2.8rem;
        border-top: 1px solid #E5EDF1;
    }
    .boss-item {
        width: 3.65rem;
        height: 3.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 34px;
        position: relative;
        // transform: perspective(1000px);
        // transform: perspective(1000);
        transform: perspective(1000px);
        -o-transform: perspective(1000px);
        -webkit-transform: perspective(1000px);
        &:last-of-type{
            border-right:none
        }
        img{
            width: inherit;
            height: inherit;
        }
        .text-desc {
            width: 100%;
            height: 100%;
            position:absolute;
            top: 0;
            left: 0;
            z-index: 9;
            background: #303C5B;
            opacity: .5;
            color: #fff;
            font-size: .18rem;
            transform-style: preserve-3d;
            transform: rotateY(90deg);
            transition: all 0.50s ease-in-out 0s;
        }
        .item-mask{
            width: 100%;
            height: 100%;
            position:absolute;
            top: 0;
            left: 0;
            transform: rotateY(0);
            transition: all .5s ease-in-out 0s;
        }
        
        &:hover{
            .text-desc {
                transform: rotateY(0);
            }
            .item-mask{
                transform: rotateY(90deg);
            }
        }
    }
    .open-jobs{
        padding: 100px 2.8rem;
        background: #F5F8FB;
    }

    .left-area {
        width:7.38rem;
        height: 5.06rem;
        margin-right: 1.06rem;
        text-align: left;
    }
    .right-area-life {
        width: 9.03rem;
        // flex:1;
        // height: 318px;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .life-title {
        font-size: 11px;
        font-family: AvenirNext-Regular, AvenirNext;
        font-weight: 400;
        color: #30425A;
        line-height: 15px;
        letter-spacing: 1px;
    }
    .company-life-title{
        font-size: 28px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 40px;
        letter-spacing: 4px;
        margin-top: 45px;
    }
    .life-description {
        font-size: 16px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 32px;
        letter-spacing: 2px;
        margin-top: 15px;
        white-space: nowrap;
    }
    @media all and (max-width: 1017px) {
        .company-life {
            flex-wrap: wrap;
        }
        .left-area,.right-area-life {
            width: 100%;
            height: auto;
            flex:auto
        }
        // .banner-img{
        //     flex-wrap: wrap;
        //     height: 100%;
        // }
        // .left-news-descrip {
        //     width: 100%;
        //     height: auto;
        // }
        // .right-top-img {
        //     flex:auto;
        //     height: auto;
        // }
        
        .boss-area {
            margin: 1rem 2.8rem;
            flex-wrap: wrap;
        }
        .boss-line {
            display: none;
        }
        .boss-item {
            width: 14.6rem;
            height: 14.6rem;
            max-width: 365px;
            max-height: 365px;
            & *{
            border: none;
            }
        }
        .open-jobs{
            flex-wrap: wrap;
        }
        .job-list {
            width: auto;
        }
        .left-area{
            height: auto;
            margin-bottom: 50px;
        }
         .job-item{
             width: 100%;
            padding: 20px .75rem;
         }
         .city-item {
             margin-right: .32rem;
              margin-bottom: .32rem;
         }
         .city-select {
             flex-wrap: wrap;
         }

    }
}
</style>

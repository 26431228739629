<template>
    <div class="news-report-page">
        <!-- 新闻报道 -->
        <div class="flex banner-img pointer" @click="goNewsDetail">
            <div class="left-news-descrip">
                <div class="first-title">
                    NEWS
                </div>
                <div class="second-title">
                    {{topInfo.title}}
                </div>
                <div class="top-news-content line-cut2">
                    {{topInfo.top_section}}
                </div>
                <div class="top-media-name">
                    {{topInfo.media_name}}
                </div>
                <div class="white-line-decorate mrt23">
                </div>
            </div>
            <div class="right-top-img">
                <img :src="topInfo.top_img" alt="">
            </div>
        </div>
        <div class="news-list">
            <div class="flex news-item" v-for="item,index in newslist" :key="index" @click="goNews(item)">
                <div class="media-img">
                    <img :src="item.media_img" alt="">
                </div>
                <div class="news-description">
                    <div class="line-decorate">
                    </div>
                    <div class="date-time">
                        {{item.news_date}}
                    </div>
                    <div class="news-title">
                        {{item.title}}
                    </div>
                    <div class="media-name">
                        {{item.media_name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { Button } from 'ant-design-vue';

import IndexApi from '../api/index';
export default {
    components: {
        // [Button.name]:Button
    },
    data() {
        return {
            theme2: 'light',
            newslist: [],
            topInfo: {}
        };
    },
    created(){
        this.initData();
    },
    methods: {
        goNewsDetail(){
            window.open( this.topInfo.link_url);
        },
        goNews(item){
            window.open(item.link_url);
        },
        async initData() {
            let data = await  IndexApi.news();
            this.topInfo = data.top_info;
            this.newslist = data.list;
            console.log('news', data);
            //   const {carousel,scene,text,about_video} = data
        },

    }
};
</script>
<style lang="less" scoped>
.news-report-page {
    // .first-title {
    //     font-size: .6rem;
    //     font-family: Avenir-Book, Avenir;
    //     font-weight: normal;
    //     color: #FFFFFF;
    //     line-height: .82rem;
    //     letter-spacing: .12rem;
    // }
    // .second-title {
    //     font-size: .28rem;
    //     //
    //     font-weight: 400;
    //     color: #FFFFFF;
    //     line-height: .4rem;
    //     letter-spacing: 4px;
    //     margin-top: .22rem;
    //     // min-width: 418px;
    // }
    // .top-news-content {
    //     font-size: .18rem;
    //     //
    //     font-weight: 400;
    //     color: #FFFFFF;
    //     line-height: .32rem;
    //     letter-spacing: 2px;
    //     margin-top: .24rem;
    // }

    .news-list {
        padding: 60px 2.8rem;
    }
    .news-item {
        // height: 221px;
        padding: 40px 78px;
        &:hover{
            box-shadow: 0 5px 20px rgba(48, 66, 90, 0.1);
            transition: all .3s cubic-bezier(.25,.46,.45,.94);
            transition-property: box-shadow,opacity,visibility,transform,-webkit-box-shadow,-webkit-transform;
        }
    }
    .news-description {
        text-align: left;
    }
    .media-img {
        width: 292px;
        height: 141px;
        background: #FFFFFF;
        border: 1px solid #E4E7EB;
        margin-right: 88px;
        img{
            width: inherit;
        }
    }
    .date-time {
        font-size: 11px;
        font-family: AvenirNext-Regular, AvenirNext;
        font-weight: 400;
        color: #30425A;
        line-height: 15px;
        letter-spacing: 1px;
    }
    .news-title{
        font-size: 18px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 32px;
        letter-spacing: 2px;
        margin-top: 16px;
    }
    .media-name{
        margin-top: 32px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 32px;
        letter-spacing: 1px;
    }
    @media all and (max-width: 1017px) {
        .news-item{
            flex-wrap: wrap;
            height: auto;
            padding: 40px .78rem;
        }
        .media-img {
            width: 100%;
            height: auto;
            margin-right: 0;
            margin-bottom: 30px;
        }
        .media-name {
            margin-top: .32rem;
        }
        .news-list {
            padding: 60px 1rem;
        }
    }
}
</style>

<template>
    <div>
        <div class="big-screen">
            <div class="layout-nav-header flex align-center flex-between " :class="{'fixed-styl':scrollHeight>1}">
                <div class="left-area flex align-center">
                    <div class="logo">
                        <i class="logo-img"></i>   
                    </div>
                    <div class="language">
                        LANGUAGE
                    </div>
                    <div class="flex  align-center" style="margin-left:12px">
                        <div class="lang-item " :class="{
                            'lang-active':CurrentLang==1
                        }" @click="langSelect(1)">
                            CN
                        </div>
                        <div class="left-border">

                        </div>
                        <div  class="lang-item" :class="{
                            'lang-active':CurrentLang==2
                        }"  @click="langSelect(2)"> 
                            EN
                        </div>
                    </div>
                </div>
                <div class="right-area">
                    <div class=" flex align-center">
                        <div
                            v-for="item,index in navList"
                            class="nav-item" 
                            :class="{'animation-nav':show==index}"
                            :key="index"
                            @mouseover="()=>{
                                show=index;
                                click=false
                            }"
                            @mouseout="()=>{
                                show=-1;
                            }"
                            @click="navChange(item,index)"
                        >
                            <div class="pos-real">
                                {{item.title}}
                                <div class="active_border" v-if="currentIndex===index">

                                </div>
                            </div>
                            <product-component 
                                @leave="leave" class="product-component"
                                :class="{'prod-nav':show==index&&item.is_pull}"
                                v-show="show==index&&item.is_pull" :list="productList"></product-component>
                        </div>
                        <a-button class="contact-us" type="primary" @click="contact">{{$dataConfig.contact}}</a-button>
                    </div>
                </div>
                <!-- <div class="mask" v-show="show!=-1">
        </div> -->
            </div>
        </div>
        <div class=" small-screen ">
            <div class="flex flex-between">
                <div class="left-area flex align-center">
                    <div class="logo">
                        <i class="logo-img"></i>   
                    </div>
                </div>
                <div class="collapse-area" @click="collapseMenu">
                    <div class="line-icon"> |||</div>
                    <div class="font12 menu-text">MENU</div>
                </div>
            </div>
            <div class="collapse-menu-list" v-show="isCollpase">
                <div class="nav-item close-line">
                    <div class="close-icon" @click="isCollpase=!isCollpase;openDevice = false;"></div>
                </div>
                <div class="padding-top52" 
                > 
                    <div class="nav-item flex  align-center">
                        <div class="language smno-margin white-color">
                            LANGUAGE
                        </div>
                        <div class="flex  align-center" style="margin-left:20px">
                            <div class="lang-item link-gray" style="margin-right:12px" :class="{
                                'lang-active-small':CurrentLang==1
                            }" @click="langSelect(1)">
                                CN
                            </div>
                            <div  class="lang-item link-gray" :class="{
                                'lang-active-small':CurrentLang==2
                            }"  @click="langSelect(2)"> 
                                EN
                            </div>
                        </div>
                    </div>
                    <template v-for="item,index in navList">
                        <div
                            :key="index"
                            class="nav-item flex flex-between" 
            
                            @click="navChangeH5(item,index)"
                        >
                            <div class="flex flex-between w100 align-center" :class="{'nav-line-border ': !openDevice}">
                                <div >
                                    {{item.title}}</div>
                                <div class="arrow-icon"  :class="{
                                    'del-icon':openDevice
                                }" v-if="item.is_pull" >
                                </div>
                            </div>
                        </div>
                        <template v-if="item.is_pull&&openDevice">
                            <div  class="allgraybg"
                                  :key="i+'ch'"
                                  v-for="elem,i in item.pull_list">
                                <div class="border-bottom-line">
                                    <div
                                    
                                        class="nav-item" 
                                        style="padding:0"
                                    >
                                        <div class="border-bottom-line">
                                            {{elem.cate_name}}
                                        </div>  
                                    </div>
                                    <div class="nav-item product_title"  
                                         v-for="el,n in elem.product_list" 
                                         @click="goProductDetail(el.product_id)"
                                         :key="String(i)+n+'p'">
                                        {{el.product_title}}
                                    </div>
                                </div>
                            </div>

                        </template>
                    </template>
                </div>
                <div class="nav-item"   @click="contact">
                    {{$dataConfig.contact}}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { Button } from 'ant-design-vue';
import ProductModule from './Product';
import IndexApi from '../api/index';
import debounce from 'lodash.debounce';
const isEn = localStorage.getItem('language')==='en';
export default {
    components: {
        [Button.name]: Button,
        'product-component': ProductModule
    },
    data() {
        return {
            CurrentLang: isEn?2: 1,
            navList: [],
            theme2: 'light',
            currentIndex: null,
            productList: [],
            needDisplay: false,
            routerName: '',
            show: -1,
            click: false,
            scrollHeight: 0,
            isCollpase: false,
            openDevice: false
        };
    },
    watch: {
        // $route: {//深度监听，可监听到对象、数组的变化
        //     handler(val){
        //         if(val){
        //             this.currentIndex = this.navList.findIndex((item) => {
        //                 return   new RegExp(item.link_url).test(this.routerName );
        //             }); 

        //         }
        //     },
        //     deep: true //true 深度监听
        // }
    },
    // watch: {
    //     show: {//深度监听，可监听到对象、数组的变化
    //         handler(val){
    //             if(val===-1){
    //                 this.$emit('product-show', false);
    //             }else{
    //                 this.$emit('product-show', true);  
    //             }
    //         },
    //         deep: true //true 深度监听
    //     }
    // },
    created() {
        this.initNav();
        window.addEventListener('scroll', this.scrollPage, true);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.scrollPage, true);
    },
    mounted(){
        this.routerName=  this.$route.name;
    },
    methods: {
        langSelect(index){
            this.CurrentLang = index;
            localStorage.setItem('language', index===1?'cn':'en') ;
            location.reload();
        },
        collapseMenu(){
            this.isCollpase = true;
        },
        scrollPage(){
            const handleScroll = debounce(async () => {
                const curTop = document.documentElement.getBoundingClientRect().top || document.body.scrollTop || document.documentElement.scrollTop;

                this.scrollHeight = Math.abs(curTop);
            }, 200, { maxWait: 400 });
            handleScroll();
        },
        async initNav() {
            let res = await  IndexApi.indexNav();
            const {list} = res;
            this.navList = list;
            const index = list.findIndex((item) => {
                return item.is_pull;
            });
            this.currentIndex = list.findIndex((item) => {
                return   new RegExp(item.link_url).test(this.routerName );
            }); 
            console.log(this.currentIndex );  
            this.productList = list[index].pull_list;
        },
        goProductDetail(id){
            this.isCollpase  = false;
            this.openDevice = false;
            this.$emit('leave', false);
            this.$router.push({
                name: 'device',
                params: {
                    id,
                },
                query: {
                    source_inside: 'nav'     
                }
            });
        
        },
        navChange(item, index) {
            if(item.is_pull){
                return;
            }
            this.click = true;
            this.currentIndex = index;
            this.$router.push({
                name: item.link_url
            });
        },
        navChangeH5(item){
            if(item.is_pull){
                this.openDevice = !this.openDevice;
                return;
            }else{
                this.openDevice = false;
            }
            this.isCollpase = false;
            this.$router.push({
                name: item.link_url
            });
        },
        contact(){
            this.openDevice = false;
            this.isCollpase = false;
            this.currentIndex  = null;
            this.$router.push({
                name: 'contact'
            });   
        },
        leave(){
            this.click = true;
            const index = this.navList.findIndex((item) => {
                return item.is_pull;
            });
            this.currentIndex = index;
            this.needDisplay = false;
            this.$emit('product-show', false);
        },

    }
};
</script>
<style lang="less" scoped>
        @keyframes prodList
        {
                0% {opacity: 0;}
                100% {
                    opacity: 1;
                }
        }
        @keyframes myfirst
        {
                0% {background: #fff;color: #182649;opacity: 0;}
                50%{
                    background:rgba(24, 38, 73, 45%);
                    color:rgba(255, 255, 255, 0.5);
                    opacity: .5;
                }
                100% {
                    background: #182649;
                    color: #fff;
                    opacity: 1;
                }
        }
            
        .animation-nav {
                background:#182649;
                color:#fff;
                 transition: all .5s linear;
                // animation: myfirst .7s ease-in;
                // animation-fill-mode:forwards
        }
        .prod-nav {
                opacity: 1;
                //  transition: all 1.5s linear;
                animation: prodList .4s ease-in;
                animation-fill-mode:forwards
        }
    .collapse-menu-list {
        width: 100%;
        min-height: 20px;
        background: #303C5B;
        z-index: 11;
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        overflow: scroll;
        color: #fff;
        .close-line {
            width: 100%;
            position: fixed;
            top:0;
            background: #303C5B;
            z-index: 12;
        }
        .padding-top52{
             margin-top: 52px;
        }
        .product_title {
            // opacity: .7;
            color: rgba(255, 255, 255, .7) !important;
            // padding-left: 50px !important;
        }
        .close-icon {
            text-align: right;
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 22px;
            width: 16px;
            height: 16px;
            background: url('../assets/close@2x.png') no-repeat 0% 0% / contain;
        }
        .arrow-icon{
            width: 12px;
            height: 12px;
            background: url('../assets/add-icon@2x.png') no-repeat 0% 0% / contain;
        }
        .del-icon {
            width: 12px;
            height: 12px;
            background: url('../assets/del@2x.png') no-repeat 0% 0% / contain;

        }

        .nav-item{
            height: 52px;
            line-height: 52px;
            // background:#fff;
            // color:#182649 ;
            font-size: 13px;
            padding: 0 25px;
            text-align: left;
            // &:hover{
                // background:#182649;
                // color:#fff;
                //  transition: all 1s linear;
                // animation: myfirst 1s;
                // background: #182649;
                // transition: background .8s ease-in;  
            // }
        }
        .nav-line-border{
            border-bottom:  1px solid #636C83;;
        }
        .allgraybg {
            background: #182649;
            padding: 0 25px;
        }
        .active{
            background: #182649;
        }
    }
    .collapse-area{
        width: 64px;
        height: 1.8rem;
        background: #303C5B;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        color: #fff;
        margin:  4px;
        .line-icon {
            display: inline-block;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg); /* IE 9 */
            -moz-transform: rotate(90deg); /* Firefox */
            -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
            -o-transform: rotate(90deg); /* Opera */
        }
    }
    .mask{
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 999
    }
        .logo-img{
            display: inline-block;
            width: 149px;
            height: 33px;
            position: relative;
            top: 3px;
            background: url('../assets/lxlogo-white.png') no-repeat 0% 0% / contain;
        }
        .language{
            font-size: 14px;
            font-family: ArialMT;
            color: #303C5B;
            line-height: 16px;
            letter-spacing: 1px;
            margin-left: 40px;
        }
        .logo {
            width: 280px;
            height: 150px;
            line-height: 170px;
            background: #303C5B;
            position: relative;
            z-index: 9;
            font-size: 22px;
            font-family: DINCondensed-Bold, DINCondensed;
            font-weight: bold;
            color: #FFFFFF;
            transition:height .5s;
        }
    .layout-nav-header {
        position: relative;
        width: 100%;
        height: 1rem;
        background: #FFFFFF;
        padding-right: .84rem;
        transition:height .5s;
        .nav-item {
            width: 1.36rem;
            height: 1rem;
            font-size: .18rem;
            line-height: 1rem;
            margin: .37rem .14rem;
            cursor: pointer;
            text-align: center;
            justify-content: center;
            display: flex;
        }
        .active{
            background: #303C5B;
            //
            font-weight: 400;
            color: #FFFFFF;
        }
        .contact-us{
            margin-left: .2rem;
        }
    }
    .fixed-styl{
        position: fixed !important;
        width: 100%;
        height: 60px;
        z-index: 999;
        background: #fff;
        top: 0;
        box-shadow: 0px 5px 20px 0px rgba(48, 66, 90, 0.1);
        transition:height .5s;
        .nav-item{
            height: 60px;
            line-height: 60px;
            transition:height .5s;
        }
        .logo {
            height: 60px;
            transition:height .5s;
            font-size: 22px;
            font-weight: bold;
            color: #001058;
            background: #FFFFFF;
            line-height: 76px;
            letter-spacing: 1px;
        }
        .logo-img{
            background: url('../assets/logo-black@2x.png') no-repeat 0% 0% / contain !important;
        }
        .contact-us.ant-btn-primary{
            height: .44rem;
            line-height:.44rem
        }
        .product-component {
            top: 60px;
            opacity: 0;
        }
    }
    .small-screen {
        display: none;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    @media all and (max-width: 828px) {
        .big-screen {
            display: none;
        }
        .small-screen {
            display:block
        }
        .logo{
            width: 144px;
            height: 77px;
            line-height: 77px;
            font-size: 14px;
        }
        .logo-img{
            display: inline-block;
            width: 35.5px;
            height: 13.5px;
        }
        .collapse-area {
            height: 77px;
        }
        .menu-text,.line-icon {
            margin-top: 10px;
        }

    }
</style>
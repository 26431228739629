<template>
    <div class="company-info-page">
        <div class="banner-img flex">
            <div class="left-news-descrip">
                <div class="first-title">
                    ABOUT
                </div>
                <div class="second-title">
                    {{$dataConfig.companyName}}
                </div>
                <div class="top-news-content">
                    {{topArea.content}}
                </div>
                <div class="margin-top114 white-line-decorate">
                    
                </div>
            </div>
            <div class="right-top-img">
                <img :src="topArea.img_url" alt="">
            </div>
        </div>
        <template v-for="item,index in otherArea">
            <div class="about-item" :key="index">
                <div class="about-text-area flex" v-if="item.block_type==2">
                    <div class="left-img">
                        <img :src="item.img_url" alt="">
                    </div>
                    <div class="text-description">
                        <div class="text-title">
                            {{item.title}}
                        </div>
                        <div class="text-content">
                            <span>{{item.content[0]}}</span>
                            <span>{{item.content[1]}}</span>
                        </div>
                    </div>
                </div>
                <div class="img-list flex flex-wrap flex-between" v-if="item.block_type==3">
                    <div  class="img-item " v-for="elem,i in item.img_files" :key="i">
                        <img :src="elem.img_url" alt="">
                    </div>
                </div>
                <div class="video-list flex flex-wrap flex-between" v-if="item.block_type==4">
                    <div  class="img-item"  v-for="elem,i in item.video_files" :key="i">
                        <VideoPlay 
                            id="my-video_html5_api"
                            v-if="elem.video_url" 
                            :poster-url="require('../assets/video-first@2x.png')" 
                            :video-url="[{url:elem.video_url}]"
                        ></VideoPlay>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>
<script>
import IndexApi from '../api/index';
import VideoPlay from '../components/videoPlay.vue';

export default {
    components: {
        VideoPlay
    },
    data() {
        return {
            theme2: 'light',
            topArea: {},
            otherArea: []
        };
    },
    created(){
        this.initData();
    },
    methods: {
        async initData() {
            let data = await  IndexApi.about();
            this.topArea = data.list[0];
            this.otherArea = data.list.slice(1);
        },
    }
};
</script>
<style lang="less" scoped>
.company-info-page {
    .img-list,.video-list {
        padding:80px 2.8rem 0 ;
        border-bottom: 1px solid #E5EDF1;
        .img-item{
            // width: min-content;
            width: 6.4rem;
            // height: 3.44rem;
            // padding-bottom: 80px;
            background: #E5EDF1;
            margin-bottom: .8rem;
            img{
                width: inherit;
                height: inherit;
            }
        }
    }
    // .right-top-img {
    //     height: 100%;
    //     img{
    //         width: inherit;
    //         height: -webkit-fill-available;
    //     }
    // }
    .about-text-area {
        border-top: 1px solid #E5EDF1;;
        padding: 80px 2.8rem 60px;
        margin-top: 80px;
    }
    .left-img{
        width: 6.4rem;
        height: 3.44rem;
        background: rgba(0, 47, 167, 0.05);
        margin-right: 60px;
        img{
            width: inherit;
        }
    }
    .text-description {
        text-align: left;
    }
    .text-title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2F353D;
        line-height: 32px;
        letter-spacing: 2px;
        text-decoration: underline;
    }
    .text-content {
        font-size: 16px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 32px;
        letter-spacing: 2px;
        margin-top: 37px;
    }
    @media all and (max-width: 1017px) {
            .about-text-area {
                flex-wrap: wrap;
                padding: 80px 1rem 60px;
            }
            .left-img {
                width: 100%;
                height: auto;
                margin-right: 0;
                margin-bottom: 30px;
            }
            .img-list,.video-list {
                padding: 80px 1rem 60px;  
                .img-item  {
                    width: 100% !important;
                    height: auto;
                }
            }

    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"text-left contact-header"},[_c('div',{staticClass:"contact-title"},[_vm._v(" Contact ")]),_c('div',{staticClass:"contact-us",class:{
            'font40':_vm.isEn
        }},[_vm._v(" "+_vm._s(_vm.$dataConfig.contact)+" ")]),_c('div',{staticClass:"white-line-decorate mrt178"})]),(!_vm.haveSummit)?_c('div',{staticClass:"contact-form-page"},[_c('div',{staticClass:"text-left contact-description"},[_vm._v(" "+_vm._s(_vm.$dataConfig.contactText1)+"。"),_c('br'),_vm._v(" "+_vm._s(_vm.$dataConfig.contactText2)+"。"),_c('br'),_vm._v(" "+_vm._s(_vm.$dataConfig.contactText3)+"。 ")]),_c('div',{staticClass:"contact-form"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"form-border-line ant-col ant-col-24"})]),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.username}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            { rules: [{ required: true, message:_vm.$dataConfig.placeholder1 }] } ]),expression:"[\n                            'name',\n                            { rules: [{ required: true, message:$dataConfig.placeholder1 }] },\n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.placeholder1}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.email}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            { rules: [
                                { 
                                    required: true,
                                    message:_vm.$dataConfig.placeholder2  
                                },
                                {
                                    pattern: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
                                    message: _vm.$dataConfig.placeholder21
                                }
                            ] } ]),expression:"[\n                            'email',\n                            { rules: [\n                                { \n                                    required: true,\n                                    message:$dataConfig.placeholder2  \n                                },\n                                {\n                                    pattern: /^([a-zA-Z]|[0-9])(\\w|-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$/,\n                                    message: $dataConfig.placeholder21\n                                }\n                            ] },\n                            \n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.placeholder2}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.linkMethods}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'phone',
                            { rules: [
                                { required: true, message:  _vm.$dataConfig.placeholder3 }
                            ] } ]),expression:"[\n                            'phone',\n                            { rules: [\n                                { required: true, message:  $dataConfig.placeholder3 }\n                            ] },\n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.placeholder3}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.yourCompany}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company',
                            { rules: [{ required: true, message:_vm.$dataConfig.placeholder4  }] } ]),expression:"[\n                            'company',\n                            { rules: [{ required: true, message:$dataConfig.placeholder4  }] },\n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.placeholder4}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.work}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'jobtitle',
                            { rules: [{
                                required: true, 
                                message:_vm.$dataConfig.yourTitle 
                            }] } ]),expression:"[\n                            'jobtitle',\n                            { rules: [{\n                                required: true, \n                                message:$dataConfig.yourTitle \n                            }] },\n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.yourTitle}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$dataConfig.yourAsk}},[_c('a-input-text-area',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'content',
                            { rules: [{ required: false, }] } ]),expression:"[\n                            'content',\n                            { rules: [{ required: false, }] },\n                        ]"}],attrs:{"placeholder":_vm.$dataConfig.placeholder5}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{staticClass:"submit-contact",attrs:{"type":"primary"},on:{"click":_vm.check}},[_vm._v(" "+_vm._s(_vm.$dataConfig.sureSubmit)+" ")])],1)],1)],1)]):_c('div',{staticClass:"contact-suceess-info"},[_c('div',{staticClass:"success-icon"}),_c('div',{staticClass:"success-info-message"},[_vm._v(" "+_vm._s(_vm.$dataConfig.successMsg)+" ")]),_c('div',{staticClass:"tel-desc"},[_vm._v(" "+_vm._s(_vm.$dataConfig.msg2)),_c('br'),_vm._v(" "+_vm._s(_vm.$dataConfig.msg3)+" ")]),_c('a-button',{staticClass:"success-contact",attrs:{"type":"primary"},on:{"click":_vm.backForm}},[_vm._v(" "+_vm._s(_vm.$dataConfig.backPage)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
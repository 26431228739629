<template>
    <div class="end-device-page">
        <div class="product-detail-header flex flex-between">
            <div class="left-area-description">
                <div class="product-detail-name">
                    {{productDetail.title}}
                </div>
                <div class="product-top-title">
                    {{productDetail.top_title}}
                </div>
                <div class="product-top-content">
                    {{productDetail.top_content}}
                </div>
                <div class="button-bg contact-us-button" @click="contactUs">
                    CONTACT
                </div>
            </div>
            <div class="right-product-img">
                <img :src="productDetail.top_img" alt="">
            </div>
        </div>
        <div class="product-sale text-left padding">
            <div class="line-decorate">
            </div>
            <div class="sale-header">PRODUCT ADVANTAGES</div>
            <div class="sale-detail-area flex flex-between">
                <div  class="left-area text-left">
                    <div class="sale-title">
                        {{productDetail.sell_title}}
                    </div>
                    <div class="sell-scene">
                        {{productDetail.sell_content}}
                    </div>
                    <div class="sale-keywords">
                        <div class="keywords-item" v-for="item,index in productDetail.sell_keyword" :key="index">
                            {{item}}
                        </div>
                    </div>
                    <div class="application-area">
                        {{productDetail.sell_scene}}
                    </div>
                </div>
                <div class="right-area">
                    <img :src="productDetail.sell_img" alt="">
                </div>
            </div>
        </div>
        <div class="technical-indicators text-left padding">
            <div class="line-decorate">
            </div>
            <div class="technical-header">PRODUCT ADVANTAGES</div>
            <div class="technical-detail-area flex flex-between">
                <div class="left-technical">
                    <div class="tec-title">
                        {{productDetail.tec_title}}
                    </div>
                    <div class="tec-content">
                        {{productDetail.tec_content}}
                    </div>
                </div>
                <div class="right-technical-img">
                    <img :src="productDetail.tec_img" alt="">
                </div>
            </div>
        </div>
        <div class="device-params text-left padding">
            <div class="line-decorate">
            </div>
            <div class="device-header">SPECIFICATIONS</div>
            <div class="device-title">
                {{$dataConfig.deviceParams}}
            </div>
            <div class="device-detail-area flex flex-start text-left">
                <div class="left-device-img">
                    <img :src="productDetail.param_img" alt="">
                </div>
                <div  class="right-params-list">
                    <div class="right-params-item" v-for="item,index in productDetail.detail_param" :key="index">
                        <div class="flex">
                            <div class="left-device-description flex align-center" :class="{
                                'en-device-description':isEn
                            }">
                                <img class="device-icon" :src="item.cover_img" alt="">
                                <span class="device-params-name">{{item.name}}</span>
                            </div>
                            <div class="right-device-content">
                                {{item.content}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail-carsouel recommendPage" v-if="productDetail.detail_picture">
            <swiper :options="swiperOption" class="flex pointer" ref="mySwiper">
                <swiper-slide class="flex1" v-for="(item,index) in productDetail.detail_picture" :key="index">
                    <img :src="item" alt="">
                </swiper-slide>
            </swiper>
        </div>
        <div class="product-list  text-left padding">
            <div class="line-decorate">
            </div>
            <div class="product-header">SPECIFICATIONS</div>
            <div class="product-title"> {{$dataConfig.prodList}}</div>
            <div class="product-select flex flex-wrap align-center">
                <template  v-for="item,index in productList">
                    <div class="cate-item pointer"
                         @click="slectCate(index)"
                         v-if="item.cate_name"
                         :key="index"
                         :class="{'product-active':currentIndex==index}"
                    >
                        {{item.cate_name}}
                    </div>
                </template>
            </div>
            <div class="all-product-list align-center flex flex-wrap" v-if="productList.length">
                <div class="prod-item flex-wrap" 
                     v-for="elem,i in productList[currentIndex].product" 
                     :key="i"
                     @click="prodItemClick(elem.id)"
                >
                    <div class="product-title"> 
                        {{elem.title}}
                        <div class="line-animation">
                        </div>
                    </div>
                    <div class="product-img">
                        <img :src="elem.cover_img" alt="">
                    </div>
                    <div class="line-border" v-if="!(((i+1) % 3)==0) ">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import IndexApi from '../api/index';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
    components: {
        swiper,
        'swiper-slide': swiperSlide
    },
    data() {
        return {
            isEn: localStorage.getItem('language')==='en',
            currentIndex: 0,
            productList: [],
            productDetail: {},
            swiperOption: {
                loop: true,
                // loopedSlides: 3,
                slidesPerView: 3,
                zoom: true,
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                }
            }
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper&&this.$refs.mySwiper.swiper;
        },
        
    },
    watch: {
        $route: {//深度监听，可监听到对象、数组的变化
            handler(val){
                if(val){
                    console.log('VAL', val);
                    this.getProductDetail();
                    location.reload();
                }
            },
            deep: true //true 深度监听
        }
    },
    mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
        console.log('this is current swiper instance object', this.swiper);
    // this.swiper.slideTo(3, 1000, false);
    },
    created(){
        this.initData();
        this.getProductDetail();
    },
    methods: {
        slectCate(index){
            this.currentIndex = index;
        },
        contactUs(){
            this.$router.push({
                name: 'contact'
            });
        },
        prodItemClick(id){
            
            this.$router.push({
                name: 'device',
                params: {
                    id,
                },
                query: {
                    source_inside: 'product'     
                }
            });
        },
        async initData() {
            let data = await  IndexApi.product();
            const {list} = data;
            const all = [];
            list.forEach((item) => {
                all.push(...item.product);
            });
            let obj = {
                cate_name: '全部',
                product: all
            };
            list.unshift(obj);
            this.productList = list;
        },
        async getProductDetail(){
            let source_inside =  this.$route.query.source_inside;
            const {id} = this.$route.params;
            let data = await  IndexApi.productDetail({id, source_inside});
            this.productDetail = data;
            console.log('productDetail', data);
        }
    }
};
</script>
<style lang="less" scoped>
.end-device-page {
    .swiper-slide-active{
        background:#ff6600;
        color:#fff;
    }
    .recommendPage .swiper-container{
        position: relative;
        width: 100%;
        height: 4.9rem;
        background: #F5F8FB;
        img{
            width: inherit;
            height: inherit;
        }
    }  
    .recommendPage .swiper-container .swiper-slide{
        width: 100%;
        // line-height: 379px;
        background: #F5F8FB;
        color: #000;
        font-size: 16px;
        text-align: center;
    }
    .product-select{
        padding-bottom:61px ;
        border-bottom: 1px solid #E5EDF1;

    }
    .product-title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2F353D;
        line-height: 32px;
        letter-spacing: 2px;
        margin-bottom: 54px;
    }
    .all-product-list{
        // margin-top: 78px;
    }
    .product-img {
        width: 3.65rem;
        height: 2.06rem;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .product-title {
        font-size: .24rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2F353D;
        // width: fit-content;
        position: relative;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        
        &::before,&::after{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: rgba(26,26,26,.7);
            content: "";
            // transition: transform .5s  ease-in;
        }
         &::before{
            transform: translateX(0);

         }
         &::after{
            transform: translateX(-100%);

         }

    }
    .line-animation {
        width: 100%;
        height: 1px;
        background: #30425A;
        transform: translateX(0);
    }
    .prod-item {
        cursor: pointer;
        width: 4.53rem;
        // height: 4.34rem;
        background: #FFFFFF;
        padding: .8rem .44rem;
        position: relative;
        border-bottom: 1px solid #E5EDF1;
        &:hover{
            box-shadow: 0 5px 20px rgba(48, 66, 90, 0.1);
            transition: all .3s cubic-bezier(.25,.46,.45,.94);
            transition-property: box-shadow,opacity,visibility,transform,-webkit-box-shadow,-webkit-transform;
            z-index: 9;
            .line-animation{
                width: 0;
                transition: transform .5s .1s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .1s cubic-bezier(.165,.84,.44,1);
                transform: translateX(100%);
            }
            .scence-border-outer{
                border: none !important;
            }
            .scence-border {
                border: none !important;
            }
             .product-title {
                &:before{
                    transition: transform .5s .1s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .1s cubic-bezier(.165,.84,.44,1);
                    transform: translateX(100%);
                }
                &:after{
                    transition: transform .5s .6s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .6s cubic-bezier(.165,.84,.44,1);
                    transform: translateX(0);
                }
             }
        }
    }
    .cate-item {
        font-size: 18px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 25px;
        padding: 3px 17px;
        margin-bottom:  0.32rem;
        &:first-of-type{
            margin-left:  0;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    .product-active {
        // width: 68px;
        // height: 27px;
        text-align: center;
        background: #303C5B;
        border-radius: 14px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #FFFFFF;
        &:hover{
            text-decoration: none;
        }
    }
    .product-detail-header{
        padding: 136px 2.8rem 100px ;
        // height: 580px;
        background: #FFFFFF;
    }
    .sale-header,.technical-header,.device-header,.product-header{
        font-size: 11px;
        font-family: AvenirNext-Regular, AvenirNext;
        font-weight: 400;
        color: #30425A;
        line-height: 15px;
        letter-spacing: 1px;
        padding-bottom: 45px;
    }

    .left-device-img {
        width: 4.96rem;
        // height: 496px;
        margin-right: 122px;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .right-params-list {
        padding: 43px 0;
    }
    .right-params-item {
        margin-bottom: 16px;
    }
    .left-device-description {
        width: 3.92rem;
        margin-right: 110px;
    }
    .en-device-description {
        width: 196px;  
    }
    .device-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        // background: #30425A;
        margin-right: 19px;
    }
    .device-params{
        background: #F5F8FB;
    }
    .device-params-name {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #30425A;
        line-height: 22px;
    }
    .right-device-content {
        font-size: 16px;
        font-weight: 400;
        color: #30425A;
        line-height: 22px;
    }
    .sale-title,.tec-title,.device-title,.product-title {
        margin-top: 45px 0 24px;
        font-size: 28px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 40px;
        letter-spacing: 2px;
    }
    .device-title {
        margin-bottom: 80px;
    }
    .sell-scene,.tec-content {
        font-size: 14px;
        //
        font-weight: 400;
        color: #4C4C4C;
        line-height: 32px;
        letter-spacing: 2px;
        margin-top: 24px;
    }
    .sale-keywords {
        margin-top: 80px;
        border-left: 1px solid #2F353D;
        padding-left: 44px;
    }
    .application-area{
        width: 4.96rem;
        // height: 2rem;
        background: #303C5B;
        font-size: .16rem;
        //
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        letter-spacing: 2px;
        padding: .5rem .44rem .54rem .48rem;
        margin-top: 80px;
    }
    .keywords-item {
        font-size: 28px;
        //
        font-weight: 400;
        color: #30425A;
        line-height: 40px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }
    .left-area,.left-technical{
        // margin-right: .87rem;
        width: 493px;
        height: auto;
        padding-right: 114px;
        text-align: left;
    }
    .left-area-description {
        width: 493px;
        // height: 580px;
        padding-right: 114px;
        text-align: left;
    }
    .product-top-title {
        font-size: 28px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 40px;
        letter-spacing: 4px;
        margin: 14px 0 12px;
    }
    .product-detail-name {
        font-size: 60px;
        font-family: Avenir-Book, Avenir;
        font-weight: normal;
        color: #2F353D;
        line-height: 82px;
        letter-spacing: 12px;
    }
    .product-top-content {
        font-size: 18px;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 32px;
        letter-spacing: 2px;
    }
    .right-product-img,.right-area,.right-technical-img {
        width: 7.76rem;
        height: 4.36rem;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .product-sale{

        background: #F5F8FB;
    }
    .sale-header {
        font-size: 11px;
        font-family: AvenirNext-Regular, AvenirNext;
        font-weight: 400;
        color: #30425A;
        line-height: 15px;
        letter-spacing: 1px;
    }
     @media all and (max-width: 440px) {
         .prod-item .line-border {
             display: none;
         }
     }
    @media all and (max-width: 1017px) {
        .device-params-name,.right-device-content{
            font-size: .32rem;
        }
        .padding{
            padding: 1rem;
        }
        .product-detail-header {
            padding: calc(.6rem + 85px) 1rem 1rem;
            flex-wrap: wrap;
            height: auto;
        }

        .left-area-description,.right-product-img {
            width: 100%;
            height: auto;
            flex:auto;
            margin-bottom: 35px;
            padding-right: 1.14rem;
        }
        .sale-detail-area,.device-detail-area {
            flex-wrap: wrap;
        }
        .right-area,.right-technical-img {
            width: 100%;
            height: auto;
            margin-top: 40px;
        }
        .technical-detail-area {
            flex-wrap: wrap;
        }
        .left-device-img {
            width: 100%;
            height: auto;
            margin-right: 0;
        }
        .right-params-item {
            margin-left: 1.22rem;  
        }
        .application-area{
            font-size: 12px;
            width: auto;
             margin-top: .8rem;
        }
        .left-area,.left-technical{
            width: auto;
            padding-right: 0
        }
        .prod-item ,.product-img{
            width:100%;
            height: auto;
        }
    }
}
</style>

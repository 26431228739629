import {
    post
} from './axios';

export default {
    indexNav(parmas) {
        return post('/nav/header', parmas);
    },

    index(parmas) {
        return post('/index', parmas);
    },
    bottomNav(parmas) {
        return post('/nav/bottom', parmas);
    },
    about(parmas){
        return post('/about', parmas);
    },
    news(parmas){
        return post('/news', parmas);
    },
    recruit(parmas){
        return post('/recruit', parmas);  
    },
    recruitDetail(parmas){
        return post('/recruit/detail', parmas);  
    },
    product(parmas){
        return post('/product', parmas);  
    },
    productDetail(parmas){
        return post('/product/detail', parmas);  
    },
    subscribe(parmas){
        return post('/subscribe', parmas);  
    },
    contact(parmas){
        return post('/ask/send', parmas);  
    }
};

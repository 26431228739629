<template>
    <div class="lingxi-footer">
        <div class="flex company-info-intro flex-between">
            <div class="left-area ">
                <div class="company-name">
                    {{$dataConfig.companyAllName}}
                </div>
                <div class="mt12">
                    <div class="tel">
                        contact@lx-ar.com
                    </div>
                    <div class="tel">
                        010-82837620
                    </div>
                    <div class="address">
                        {{$dataConfig.companyAddress}}
                    </div>
                </div>
            </div>
            <div class="right-area">
                <a-input-search 
                    class="email-input-search"
                    v-model="emailInput"
                    :placeholder="$dataConfig.footEmail"
                    :enter-button="$dataConfig.submit"
                    :loading="loading"
                    size="large"
                    @search="pressEnter"
                />
                <div class="email-descr">
                    {{$dataConfig.focus}}
                    
                </div>
            </div>
        </div>
        <div class="flex friend-link flex-between align-center">
            <div class="flex left-area">
                <div class="gray">
                    {{$dataConfig.langEx}} <span style="margin-left: 16px;"> 
                        <span 
                            :class="{
                                'lang-active-bg':currentLang===1
                            }"
                            @click="changeLang(1)"
                        >
                            CN 
                            
                        </span>
                        ｜
                        <span 
                            :class="{
                                'lang-active-bg':currentLang===2
                            }"
                            @click="changeLang(2)"
                        >
                            EN
                        </span>
                    </span>
                </div>
                <div class="flex channel">
                    <div v-for="item,index in channelList" :key="index">
                        <a class="channel-item" :href="item.url" target="_blank">
                            <img :src="item.icon" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <div class="flex right-area align-center">
                <div class="flex left-margin12">
                    <div class="foot-nav" v-for="item,index in footNav" :key="index" @click="goLink(item)">
                        {{item.title}}
                    </div>
                </div>
                <div class="copright">
                    京ICP备16023589号<br/>  © 2014 - 2022 LINGXI-AR
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IndexApi from '../api/index';
import { Input, message } from 'ant-design-vue';
const isEn = localStorage.getItem('language')==='en';

export default {
    name: 'lingxi-footer',
    components: {
        [Input.name]: Input,
        AInputSearch: Input.Search,

    },
    props: {
        msg: String,
        Lang: {
            type: Number,
            default: 1
        }
        
    },
    data(){
        return {
            channelList: [
                {
                    icon: require('../assets/lingying@2x.png'),
                    url: 'https://www.linkedin.com/company/%E7%81%B5%E7%8A%80%E5%BE%AE%E5%85%89/'
                },
                {
                    icon: require('../assets/weibo@2x.png'),
                    url: 'https://weibo.com/u/7594600877'
                },
                {
                    icon: require('../assets/zhihu@2x.png'),
                    url: 'https://www.zhihu.com/people/ling-xi-wei-guang'
                },
                {
                    icon: require('../assets/facebook@2x.png'),
                    url: 'https://twitter.com/LINGXIAR'
                }
            ],
            footNav: [],
            emailInput: '',
            loading: false,
            currentLang: isEn?2:1
        };
    },
    watch: {
        Lang: {//深度监听，可监听到对象、数组的变化
            handler(val){
                if(val){
                    this.currentLang = val;
                }
            },
            deep: true //true 深度监听
        }
    },
    created(){
        this.initData();
    },
    mounted(){

    },
    methods: {
        changeLang(val){
            this.currentLang = val;
            localStorage.setItem('language', val===1?'cn':'en') ;
            location.reload();
        },
        goLink(item){
            this.$router.push({
                name: item.link_url
            });
        },
        async initData(){
            let res = await IndexApi.bottomNav();
            this.footNav = res.list;
        },
        async pressEnter(val){
            console.log('reg', val);
            // let reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            // if(!reg.test(val)){
            //     return message.error(this.$dataConfig.placeholder21);
            // }
            this.loading = true;
            try{
                await  IndexApi.subscribe({
                    email: val
                });
                message.success(this.$dataConfig.success);
            }catch(error){
                console.log('error', error);
            }finally{
                this.loading = false;
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.lingxi-footer {
    width: 100%;
    height: 340px;
    text-align: left;
    .email-input-search{
        margin-top: 64px;
    }
    .mt12 {
        margin-top: 22px;
    }
    .left-margin12 {
        margin-right: .12rem;
    }
    .email-descr {
        margin-top: 16px;
        font-size: 14px;
        //
        font-weight: 400;
        color: #E4E7EB;
        line-height: 20px;
    }
    .lang-active-bg {
        cursor: pointer;
        width: 38px;
        height: 24px;
        line-height: 24px;
        background: #FFFFFF;
        border-radius: 12px;
        text-align: center;
        display: inline-block;
    }
    .channel {
        margin-left: 76px;
        .channel-item {
            width: 24px;
            height: 24px;
            margin-right: 13px;
            img{
                width: inherit;
                height: inherit;
            }
        }
    }
    .company-info-intro {
        padding: 46px 2.8rem;
        // height: 238px;
        background: #303C5B;
    }
    .company-name {
        font-size: 28px;
        //
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
    }
    .tel{
        font-size: 16px;
        //
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-top: 8px;
    }
    .address {
        font-size: 16px;
        //
        font-weight: 400;
        color: #FFFFFF;
        background: #303C5B;
        line-height: 22px;
        margin-top: 9px;
    }
    .friend-link{
        padding: 0 2.8rem;
        height: 101px;
        background: #F1F5F9;
    }
    .copright {
        font-size: 12px;
        //
        font-weight: 400;
        color: #969696;
        line-height: 17px;
        margin-left: 30px;
    }
    .foot-nav {
        margin-right: 24px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #8890AA;
        line-height: 22px;
        cursor: pointer;
    }
    @media all and (max-width: 1017px) {
        .copright{
            margin-left: 0;
        }
        .company-info-intro {
            flex-wrap: wrap;
            padding: 1.64rem 1rem;
            height: auto;
        }
         .company-name {
             font-size: 16.8px;
             line-height: 24px;
         }
         .tel{
            font-size: 14px;
         }
         .address {
             font-size: 14px;
         }
        .left-area  {
            margin-bottom: .64rem;
            flex-wrap: wrap;
        }
        .right-area {
            width: 100%;
            flex-wrap: wrap;
            // margin-top: 25px;
            // justify-content: space-between;
        }
        .email-input-search{
            margin-top: 0;
        }
        .foot-nav {
            font-size: 14px;
            margin-right: .3rem;
        }
        .ant-input-search .ant-input{
            width: 100% !important;
            padding-left: 26px;
            
        }
        .ant-input-lg{
            padding-left: 26px;
        }
        .gray {
            font-size: 14px;
            margin-right: 12px;
            margin-bottom: 12px;
        }
        .channel{
            margin-left:  0;
            margin-bottom: 12px;
            flex-wrap: wrap;
        }
        .friend-link {
            padding: 1rem;
            height: auto;
            flex-wrap: wrap;
        }
    }
}

</style>

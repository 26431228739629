<template>
    <div id="app">
        <NavHeader/>
        <router-view ></router-view>
        <Footer/>
    </div>
</template>
<script>
import NavHeader from './components/NavHeader.vue';
import Footer from './components/Footer.vue';
export default {
    name: 'App',
    components: {
        NavHeader,
        Footer
    },
    data(){
        return {
            show: false
        };
    },
    methods: {
        productShow(bol){
            this.show = bol;
        }
    }
};
</script>

<style>
#app {
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  position: relative;
    /* overflow-x: hidden; */
}


</style>

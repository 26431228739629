<template>
    <div class="product-component flex flex-between">
        <div class="cate-item" v-for="(item,index) in list" :key="index">
            <div class="cate-name">
                {{item.cate_name}}
            </div>
            <div class="product-name" 
                 v-for="elem,i in item.product_list" :key="i"
                 @click="goProductDetail(elem.product_id)"
            > 
                {{elem.product_title}}
            </div>
        </div>
    </div>
</template>

<script>
// import IndexApi from '../api/index';
export default {
    name: 'product',
    props: {
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data(){
        return {
        };
    },
    created(){
    },
    methods: {
        goProductDetail(id){
            this.$emit('leave', false);
            this.$router.push({
                name: 'device',
                params: {
                    id,
                },
                query: {
                    source_inside: 'nav'     
                }
            });
        
        }
    }
};
</script>
<style lang="less" scoped>
.product-component {
    width: 100%;
    position: absolute;
    top: 1rem;
    left: 0;
    background: #fff;
    padding: .8rem 3.8rem;
    align-items: end;
    z-index: 9;
    border-bottom:1px solid #efefee;
    .cate-name {
        font-size: .18rem;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 25px;
        margin-bottom: 21px;
    }
    .product-name {
        font-size: .16rem;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: 30px;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}
</style>

import Vue from 'vue';
import Router from 'vue-router';
import cnData from './util/cn.json';
import enData from './util/en.json';
import CompanyInfo from './views/company-info.vue';
import Contact from './views/contact.vue';
import EndDeviceDetail from './views/end-device-detail.vue';
import Index from './views/index.vue';
import NewsReport from './views/news-report.vue';
import RecruitDetail from './views/recruit-detail.vue';
import Recruit from './views/recruit.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);
const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index,
        },
        {
            path: '/company',
            name: 'company',
            component: CompanyInfo,
        },
        {
            path: '/device/:id',
            name: 'device',
            component: EndDeviceDetail,
        },
        {
            path: '/news',
            name: 'news',
            component: NewsReport,
        },
        {
            path: '/recruit',
            name: 'recruit',
            component: Recruit,
        },
        {
            path: '/recruit-detail/:id',
            name: 'recruit-detail',
            component: RecruitDetail,
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.name) {
        const isEn = localStorage.getItem('language')==='en';
        Vue.prototype.$dataConfig = isEn
            ? enData
            : cnData;

    }
    next();
});
export default router;

<template>
    <div class="contact">
        <div class="text-left contact-header">
            <div class="contact-title">
                Contact
            </div>
            <div class="contact-us" :class="{
                'font40':isEn
            }">
                {{$dataConfig.contact}}
            </div>
            <div class="white-line-decorate mrt178">
            </div>
        </div>
        <div class="contact-form-page" v-if="!haveSummit">
            <div class="text-left contact-description">
                {{$dataConfig.contactText1}}。<br/>
                {{$dataConfig.contactText2}}。<br/>
                {{$dataConfig.contactText3}}。
            </div>
            <div class="contact-form">
                <a-form :form="form">
                    <div class="ant-row ant-form-item">
                        <div class="form-border-line ant-col ant-col-24">
                        </div>
                    </div>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.username"
                    >
                        <a-input
                            v-decorator="[
                                'name',
                                { rules: [{ required: true, message:$dataConfig.placeholder1 }] },
                            ]"
                            :placeholder="$dataConfig.placeholder1"
                        />
                    </a-form-item>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.email"
                    >
                        <a-input
                            v-decorator="[
                                'email',
                                { rules: [
                                    { 
                                        required: true,
                                        message:$dataConfig.placeholder2  
                                    },
                                    {
                                        pattern: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
                                        message: $dataConfig.placeholder21
                                    }
                                ] },
                                
                            ]"
                            :placeholder="$dataConfig.placeholder2"
                        />
                    </a-form-item>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.linkMethods"
                    >
                        <a-input
                            v-decorator="[
                                'phone',
                                { rules: [
                                    { required: true, message:  $dataConfig.placeholder3 }
                                ] },
                            ]"
                            :placeholder=" $dataConfig.placeholder3"
                        />
                    </a-form-item>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.yourCompany"
                    >
                        <a-input
                            v-decorator="[
                                'company',
                                { rules: [{ required: true, message:$dataConfig.placeholder4  }] },
                            ]"
                            :placeholder="$dataConfig.placeholder4"
                        />
                    </a-form-item>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.work"
                    >
                        <a-input
                            v-decorator="[
                                'jobtitle',
                                { rules: [{
                                    required: true, 
                                    message:$dataConfig.yourTitle 
                                }] },
                            ]"
                            :placeholder="$dataConfig.yourTitle"
                        />
                    </a-form-item>
                    <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        :label="$dataConfig.yourAsk"
                    >
                        <a-input-text-area
                            v-decorator="[
                                'content',
                                { rules: [{ required: false, }] },
                            ]"
                            :placeholder="$dataConfig.placeholder5"
                        />
                    </a-form-item>
                    <a-form-item :label-col="formTailLayout.labelCol" :wrapper-col="formTailLayout.wrapperCol">
                        <a-button class="submit-contact" type="primary" @click="check">
                            {{$dataConfig.sureSubmit}}
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="contact-suceess-info" v-else>
            <div class="success-icon">
            </div>
            <div class="success-info-message">
                {{$dataConfig.successMsg}}
            </div>
            <div class="tel-desc">
                {{$dataConfig.msg2}}<br/>
                {{$dataConfig.msg3}}
            </div>
            <a-button class="success-contact" type="primary" @click="backForm">
                {{$dataConfig.backPage}}
            </a-button>

        </div>
    </div>
</template>
<script>
import { Form, Input, Checkbox, Button, message } from 'ant-design-vue';
import IndexApi from '../api/index';
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
};
export default {
    components: {
        [Form.name]: Form,
        AFormItem: Form.Item,
        [Input.name]: Input,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        AInputTextArea: Input.TextArea,

    },
    data() {
        return {
            checkNick: false,
            formItemLayout,
            haveSummit: false,
            formTailLayout,
            form: this.$form.createForm(this, { name: 'dynamic_rule' }),
            isEn: localStorage.getItem('language')==='en',
        };
    },
    methods: {
        backForm(){
            this.haveSummit = false;
        },
        async check() {
            this.form.validateFields(async err => {
                const {name, email, phone, company, jobtitle, content} = this.form.getFieldsValue();
                if (!err) {
                    try{
                        await  IndexApi.contact({
                            name, email, phone, company, jobtitle, content
                        });
                        this.haveSummit = true;
                        message.success(this.$dataConfig.success);
                    }catch(error){
                        console.log('error', error);
                    }finally{
                        this.loading = false;
                    }
                }

            });
        },
    }
};
</script>
<style lang="less" scoped>
.contact-header{
    padding:76px 2.8rem;
    height: 506px;
    background: #303C5B;
}
   .contact-suceess-info  .success-contact.ant-btn-primary, .ant-form-item-children .submit-contact.ant-btn-primary{
        width: 161px;
        height: 54px;
        background: #303C5B;
        border: 1px solid #303C5B;
        border-radius: 4px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #FFFFFF;
        line-height: 54px;
    }
.success-info-message {
    font-size: 28px;
    //
    font-weight: 400;
    color: #2F353D;
    line-height: 40px;
    letter-spacing: 4px;
    margin-top: 80px;
}
.tel-desc {
    font-size: 16px;
    //
    font-weight: 400;
    color: #2F353D;
    line-height: 32px;
    letter-spacing: 2px;
    margin-top: 27px;
    margin-bottom: 80px;
}
.contact-form {
    padding:59px 2.8rem 100px;
}
.contact-suceess-info{
    margin: 100px auto ;
}
.contact-title{
    font-size: 60px;
    font-family: Avenir-Book, Avenir;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 82px;
    letter-spacing: 12px;
}
.contact-description {
    margin-top: 100px;
    padding-top: 40px;
    padding-left: 2.8rem;
    padding-bottom: 60px;
    border-top: 1px solid #E5EDF1;
    font-size: 16px;
    //
    font-weight: 400;
    color: #2F353D;
    line-height: 40px;
    letter-spacing: 1px;
}
.contact-us {
    font-size: 48px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 67px;
    letter-spacing: 13px;
    margin-top: 24px;
    white-space: nowrap;
}
.font40{
      font-size: 40px;  
}
.form-border-line {
    // width: 100%;
    height: 1px;
    background: #E5EDF1;
}
/deep/.ant-form-item {
    margin-bottom: 0px !important;
    .ant-form-item-label {
        // width: 200px;
        height: 72px;
        line-height: 72px;
        background: #F5F8FB;
        text-align: left;
        padding-left: 24px;
        border: 1px solid #E5EDF1;
        border-right: none;
        border-top: none;
    }
    .ant-form-item-control-wrapper{
        height: 72px;
        line-height: 72px;
        
    }

    .has-error .ant-form-explain, .has-error .ant-form-split{
        font-size: 16px;
        //
        font-weight: 400;
        color: #B04343;
        line-height: 22px;
        letter-spacing: 1px;
    }
    .ant-form-item-control,.ant-form-item-children{
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: space-between;
    }
    // .ant-form/deep/.ant-form-item-with-help{
    //     margin-bottom: 24px !important;
    // } 
    
    .ant-form-explain{
        position: absolute;
        right: 0%;
        top: 45%;
    }
    input[type='text'], input[type='password'], input[type='number'], textarea,.ant-input:placeholder-shown{
            height: inherit;
            border-left: none;
            border-right: none;
            border-radius: 0;
            border-color: #E5EDF1;
            border-top: none;

    }
    .ant-input:focus{
        box-shadow:none
    }
    .submit-contact {
        margin-top: 60px;
    }
    @media all and (max-width: 1100px) {
        .ant-form-item-label{
            display: none;
        }
        .ant-col-18{
            width: 100%;
        }
        .ant-form-explain {
            font-size: 12px !important;
            position: absolute;
            right:0;
            left:0;
            text-align: left;
            top: 0;
            bottom: 0;
            padding-left: 11px;
        }
    }

}

</style>
<template>
    <div class="index-page">
        <div class="carousel">
            <div class="carousel-copyright">
                © 2014 - 2022 LINGXI-AR
            </div>
            <a-carousel arrows dots-class="slick-dots slick-thumb"   autoplay  :after-change="afterChange">
                <template #customPaging="props">
                    <a>
                        <img :src="getImgUrl(props.i)" />
                    </a>
                </template>
                <div class="pos-real" v-for="item,index in indexInfo.carousel" :key="index">
                    <img :src="item.cover_img" />
                    <div class="pos-abs view-more-icon contact-us-button" @click="viewMore(item.relation_id)">
                       
                    </div>
                    <!-- <a-button class="view-more-btn">VIEW MORE</a-button> -->
                    <div class="text-description">
                        <div class="first-title">
                            {{$dataConfig.companyName}}
                        </div>
                        <div class="title-outline">
                            <div
                                class="second-title" 
                                :class="{
                                    'show-tag':curCarouselIndex===index,
                                    'carousel-tagline-title':curCarouselIndex!==index
                                }">
                                {{item.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </a-carousel>
        </div>
        <div class="video-show ">
            <div class="flex flex-between">
                <div class="left-area-title left-text-desc">
                    <div class="line-decorate">
                    </div>
                    <div class="text-left base-font-styl margin-btm45">
                        PROFESSIONAL WORK
                    </div>
                </div>
                <div class="right-area-line area-line-div">

                </div>
            </div>
            <div class="flex flex-between flex-wrap">
                <div class="left-text-desc" >
                    <div class="video-text-title" v-if="indexInfo.text">
                        {{indexInfo.text.title}}
                    </div>
                    <div class="video-text-content" v-if="indexInfo.text" >
                        {{indexInfo.text.content}}
                    </div>
                </div>
                <div class="right-video"
                     @mousemove="mousemove"
                     @mouseout="mouseout"
                >
                    <div class="play-area" v-show="isPlay">
                        <div>
                            <div class="play-icon">
                            </div>
                            PLAY
                        </div>
                    </div>
                    <VideoPlay 
                        v-if="indexInfo.text" 
                        :poster-url="require('../assets/video-first@2x.png')" 
                        :video-url="[{url:indexInfo.text.video_url}]"
                        @play-click="playClick"
                    ></VideoPlay>
                </div>
            </div>
        </div>
        <div class="application-scence">
            <div class="line-decorate">
            </div>
            <div class="text-left base-font-styl margin-btm45">
                Application Strategy 
            </div>
            <div class="application-title">
                {{$dataConfig.endApplication}}
            </div>
            <div class="scence-case-list white-bg flex flex-wrap">
                <div class="scence-item" v-for="item ,index in indexInfo.scene" :key="index">
                    <div class="scence-border-outer" @click="clickScence(item.relation_id)">
                        <div class="scence-border">
                            <div class="scence-title">
                                <span class="line-cut1">{{item.title}}</span> 
                                <div class="line-animation">

                                </div>
                            </div>
                            <div class="scence-content line-cut3">
                                {{item.content}}
                            </div>
                            <div class="scence-img">
                                <img :src="item.cover_img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="media-report">
            <div class="line-decorate">
            </div>
            <div class="media-subdesc">
                MEDIA REPORT
            </div>
            <div class="media-detail-area pos-real">
                <div class="media-title">
                    {{$dataConfig.mediaReport}}
                </div>
                <div class="media-list flex flex-between">
                    <div 
                        class="media-logo"
                        v-for="(item,index) in logoList"
                        :key="index"
                    >
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="earth-background pos-abs">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Button, Carousel } from 'ant-design-vue';
import VideoPlay from '../components/videoPlay.vue';
import IndexApi from '../api/index';
export default {
    components: {
        [Button.name]: Button,
        [Carousel.name]: Carousel,
        VideoPlay
    },
    data() {
        return {
            theme2: 'light',
            // carousel:[],
            logoList: [
                require('../assets/qianbi@2x.png'),
                require('../assets/zhidongxi@2x.png'),
                require('../assets/36kr@2x.png'),
                require('../assets/lieyun@2x.png'),
                require('../assets/heima@2x.png'),
                require('../assets/sutu@2x.png'),

            ],
            isPlay: true,
            indexInfo: {
                carousel: []
            },
            curCarouselIndex: 0
        };
    },
    created() {
        this.initData();
    },
    mounted() {
    },
    methods: {
        clickScence(id){
            this.$router.push({
                name: 'device',
                params: {
                    id,
                },
                query: {
                    source_inside: 'scene'     
                }
            });
        },
        playClick(bol){
            console.log(bol);
            this.isPlay = bol;
        },
        afterChange(CUR){
            console.log('cur', CUR);
            this.curCarouselIndex = CUR;
        },
        mousemove($event){
            let mouseX;
            let mouseY;
            console.log('$mousemove', $event);
            let e =$event || window.event;
            mouseX = e.layerX|| e.offsetX;
            mouseY = e.layerY|| e.offsetY; 
            let div=document.querySelector('.play-area');
            div.style.left = mouseX  + 'px';
            div.style.top = mouseY  + 'px';
            // div.style.transform =`translate(${-mouseX}, ${-mouseY})`;
            // div.style.left=$event.offsetX-50+'px';
            // div.style.top=$event.offsetY-209+'px';
        },
        mouseout($event){
            console.log('$mouseover', $event);
            let div=document.querySelector('.play-area');
            div.style.left='0';
            div.style.top='2.73rem';
            // this.isPlay = true;
        },
        viewMore(id){
            this.$router.push({
                name: 'device',
                params: {
                    id,
                },
                query: {
                    source_inside: 'carousel'
                }
            });
        },
        getImgUrl(i) {
            return this.indexInfo.carousel[i].cover_img;
        },
        async initData() {
            let data = await  IndexApi.index();
            //   const {carousel,scene,text,about_video} = data
            this.indexInfo = data;
        },

    }
};
</script>
<style lang="less">
.index-page {
    width: 100vw;
.play-area {
    width: 1.10rem;
    height: 1.10rem;
    background: #F1F5F9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .14rem;
    //
    font-weight: 400;
    color: #303C5B;
    border-radius: 100%;
    position: absolute;
    top: 2.73rem;
    left: 0;
    z-index: 9;
    pointer-events: none;
    transform: translate(-50%, -50%) scale(1);
    
}
.play-icon{
    width: .14rem;
    height: .16rem;
    background: url('../assets/play@2x.png') no-repeat 100% 100% / cover;
    margin: 0 auto;
}
.carousel{
        position: relative;
        width: 100vw;
        background: rgba(92, 122, 186, 0.3);
        height: 7.09rem;
}
.view-more-icon{
    // width: 1.93rem;
    width: 193px;
    height: 28px;
    // height: .28rem;
    background: url('../assets/view-more@2x.png') no-repeat 0% 0% / contain;
}
.carousel-copyright {
    height: 236px;
    font-size: 12px;
    //
    font-weight: 400;
    color: #FFFFFF;
    line-height: 17px;
    position: absolute;
    writing-mode: vertical-lr;
    z-index: 9;
    left: .61rem;
    top: 1.31rem;
    margin-top:.52rem
}
.media-detail-area {
    width: 100%;
    //  height: 3.98rem;;
    padding-bottom: 1.74rem;
    // background: url('../assets/Bitmap@2x.png') no-repeat 0% 0% / contain;
}
.media-report {
    padding: 1rem 2.8rem 0;
    text-align: left;
}
.media-subdesc{
    font-size: 11px;
    font-family: AvenirNext-Regular, AvenirNext;
    font-weight: 400;
    color: #30425A;
    line-height: 15px;
    letter-spacing: 1px;
}
.media-title{
    font-size: 28px;
    //
    font-weight: 400;
    color: #2F353D;
    line-height: 40px;
    letter-spacing: 4px;
    margin-top: 43px;
}
.media-list {
    margin-top: 96px;
    z-index: 9;
    position: relative;
    // margin-bottom: 1.74rem;
}
.media-logo {
    width: 160px;
    height: 63px;
    img{
        width: 100%;
    }

}
.text-description {
    position: absolute;
    top: 4rem;
    // top: calc(4rem + 85px);
    left: 2.8rem;
    z-index: 9;
    text-align: left;
}
.first-title {
    font-size:.48rem;
    // font-size: .48rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    // line-height: .67rem;
    line-height: .67rem;
    letter-spacing: 13px;
}
.earth-background {
    width: inherit;
    height: 3.98rem;
    background: url('../assets/Bitmap@2x.png') no-repeat 0% 0%/cover;
    left: 0;
    bottom: 0;
}
.second-title {
    font-size:.42rem;
    // font-size: .42rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    // line-height: .59rem;
    line-height: .59rem;
    letter-spacing: 12px;
    text-decoration:underline
}
.show-tag{
     animation: mainCopy .6s .4s cubic-bezier(.215,.61,.355,1) both;
}
.title-outline{
    position: relative;
    overflow: hidden;
    color: #fff;
    -webkit-transition: color .6s .2s linear;
    transition: color .6s .2s linear;
}
.carousel-tagline-title {
    position: absolute;
    display: block;
    opacity: 0;
    transform: translateX(-10px);
    animation: mainCopy-hide .3s cubic-bezier(.215,.61,.355,1) both;
    height: .95794em;
    &::before{
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        background: #fff;
        content: "";
        opacity: 0;
        transition: all .3s .4s cubic-bezier(.215,.61,.355,1);
        // -webkit-transition-property: opacity,-webkit-transform;
        transition-property: opacity,transform;
        -webkit-transform: scale(.55,1);
        transform: scale(.55,1);
        transform-origin: left bottom;
    }
}
@keyframes mainCopy{
    0% {
        opacity: 0;
        -webkit-transform: translateX(0) translateY(20px);
        transform: translateX(0) translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }
}
 @keyframes  mainCopy-hide {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
  }
}
.view-more {
    margin-top: 58px;
}

.video-show {
    padding: 146px 2.8rem;
    background: #fff;
}
.left-text-desc{
    width: 2.7rem;
    margin-right: 6.1%;
    text-align: left;
    min-width: 160px;
    flex: 1;

}
.video-text-title {
    font-size: .28rem;
    //
    font-weight: 400;
    color: #2F353D;
    line-height: .4rem;
    letter-spacing: .04rem;
}

.video-text-content{
    font-size: .14rem;
    font-weight: 400;
    color: #2F353D;
    line-height:.32rem;
    letter-spacing: 2px;
    margin-top: .45rem;
    margin-bottom:40px;
    word-break: break-word;
}
.right-video{
   width: 9.72rem;
    height: 5.47rem;
    background: #303C5B;
    position: relative;
    cursor: pointer;
}

.application-scence {
    padding: 1rem 2.8rem;
    background: #F1F5F9;
    text-align: left;
    .application-title {
        font-size: .28rem;
        //
        font-weight: 400;
        color: #2F353D;
        line-height: .4rem;
        letter-spacing: 4px;
        text-align: left;
        margin-bottom: 1rem;
    }
    .scence-item {
        width: 4.53rem;
        // height: 5.7rem;
        // padding: .8rem .44rem 0;
        background: #fff;
        // border-top: 1px solid #E5EDF1;
        &:hover{
            box-shadow: 0 5px 20px rgba(48, 66, 90, 0.1);
            transition: all .3s cubic-bezier(.25,.46,.45,.94);
            transition-property: box-shadow,opacity,visibility,transform,-webkit-box-shadow,-webkit-transform;
            z-index: 9;
            .line-animation{
                width: 0;
                transition: transform .5s .1s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .1s cubic-bezier(.165,.84,.44,1);
                transform: translateX(100%);
            }
            .scence-border-outer{
                border: none !important;
            }
            .scence-border {
                border: none !important;
            }
             .scence-title {
                &:before{
                    transition: transform .5s .1s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .1s cubic-bezier(.165,.84,.44,1);
                    transform: translateX(100%);
                }
                &:after{
                    transition: transform .5s .6s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s .6s cubic-bezier(.165,.84,.44,1);
                    transform: translateX(0);
                }
             }

            
            // box-shadow: 0px 0px 20px 0px rgba(48, 66, 90, 0.1);
        }
        .scence-border-outer {
            border-bottom: 1px solid #E5EDF1;
        }
        .scence-border {
            padding: .8rem .44rem 0;
            margin-bottom: .8rem;
            border-right: 1px solid #E5EDF1;
            z-index: 0;
        }
    }
    .scence-title {
        font-size: .24rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2F353D;
        // width: fit-content;
        position: relative;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        
        &::before,&::after{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: rgba(26,26,26,.7);
            content: "";
            // transition: transform .5s  ease-in;
        }
         &::before{
            transform: translateX(0);

         }
         &::after{
            transform: translateX(-100%);

         }

    }
    .line-animation {
        width: 100%;
        height: 1px;
        background: #30425A;
        transform: translateX(0);
    }
    .scence-content{
        font-size: .18rem;
        //
        font-weight: 400;
        color: #2F353D;
        margin-top: .36rem;
    }
    .scence-img{
        width: 100%;
        height: 2.06rem;
        background: rgba(0, 76, 240, 0.1);
        margin-top: .6rem;
        img{
            width: inherit;
            height: inherit;
            margin: 0 auto;
        }
    }
}
.ant-carousel :deep(.slick-dots) {
  position: relative;
  height: auto;
}
.ant-carousel .slick-slide img{
    width: inherit;
    height:inherit;
     object-fit: cover;
    // height: fit-content;
    // height:calc(100% + 85px);
    // height: calc(12.09rem + 95px) ;
}
.ant-carousel .slick-dots-bottom{
    width: 4.76rem;
    height: .83rem;
    background: #FFFFFF;
    right:2.8rem;
    padding: .08rem;
    position: absolute;
    bottom: -0.42rem;
    display: flex !important;
}
.ant-carousel .slick-dots li{
    width: 100%;
    height: 100%;
    flex: 1;
    margin:0;
    filter: opacity(.6);
    a,img {
    width: inherit;
    height: inherit;
    }
}
.ant-carousel :deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin:0 auto;
//   max-width: 80%;
}
.ant-carousel :deep(.slick-arrow) {
  display: none !important;
}
.ant-carousel :deep(.slick-thumb) {
  bottom: 0px;
}
.ant-carousel :deep(.slick-thumb li) {
  width: 60px;
  height: 45px;
}
.ant-carousel :deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
//   filter: grayscale(100%);
}
.slick-thumb li.slick-active{
 filter: opacity(1);
}
.ant-carousel :deep .slick-thumb li.slick-active img {
  filter: grayscale(50%);
}
// .ant-carousel .slick-slide img{
//     width: inherit;
//     height: fit-content;
//     object-fit: cover;
// }
@media all and (max-width: 1017px) {
    .ant-carousel .slick-slide img{
        width: inherit;
        height: inherit;
        object-fit: cover;
    }

    .ant-carousel .slick-list .slick-slide{
        pointer-events:auto
    }
    // .second-title {
    //     font-size: 22px;
    //      line-height: 29px;
    //      letter-spacing:.12rem
    // }
    .right-video{
        width: 100%;
        height: auto;
    }
    .video-text-title {
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 4px;
    }
    .video-show{
        padding:84px 1rem;
    }
    .video-text-content{
        font-size: 14px !important;
        line-height:32px;
    }
    .play-area {
        display: none;
    }
    .left-text-desc{
        margin-right: 0;
    }
    .media-title {
        margin-top: 20px;
    }
    .media-list {
        flex-wrap: wrap;
        margin-top: 50px;
    }
    .media-logo {
        width: 33.3%;
        height: auto;
    }
    .media-report {
        padding: 1rem;
    }
    .media-logo {
        width: 28%;
        margin-bottom: 30px;
    }
    .scence-img{
        width: 206px;
    }
    .application-scence {
        padding: 1rem;
        .application-title {
            font-size: 28px;
            margin-bottom: 50px;
        }
        .scence-item {
                width: 100%;
                height: inherit;
                padding: .8rem 1rem;
        }
        .scence-title {
            font-size: 24px;
            width: fit-content;
        }
        .scence-content{
            font-size: 18px;
            margin-top: .72rem;
        }
        .scence-img{
            width: 100%;
            height: auto;
            // height: 165px;
            margin-top: 1rem;
        }
    }
}

@media all and (max-width: 828px) {
    .text-description {
        position: absolute;
        // top: 3rem;
        top: calc(4rem + 95px);
        left: 2.8rem;
        z-index: 9;
        text-align: left;
    }
    .first-title {
        font-size: 28px;
        line-height: 38px;
        letter-spacing:.12rem;
    }
    .pos-real {
            height: calc(12.09rem + 95px) ;
        }
        .carousel{
            height: calc(12.09rem + 95px) ;
        }

        .carousel-copyright {
            margin-top: 4rem;
            transform: scale(.87);
        }
        .second-title {
        font-size: 22px;
         line-height: 29px;
         letter-spacing:.12rem
    }
}

}


</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"big-screen"},[_c('div',{staticClass:"layout-nav-header flex align-center flex-between ",class:{'fixed-styl':_vm.scrollHeight>1}},[_c('div',{staticClass:"left-area flex align-center"},[_vm._m(0),_c('div',{staticClass:"language"},[_vm._v(" LANGUAGE ")]),_c('div',{staticClass:"flex  align-center",staticStyle:{"margin-left":"12px"}},[_c('div',{staticClass:"lang-item ",class:{
                        'lang-active':_vm.CurrentLang==1
                    },on:{"click":function($event){return _vm.langSelect(1)}}},[_vm._v(" CN ")]),_c('div',{staticClass:"left-border"}),_c('div',{staticClass:"lang-item",class:{
                        'lang-active':_vm.CurrentLang==2
                    },on:{"click":function($event){return _vm.langSelect(2)}}},[_vm._v(" EN ")])])]),_c('div',{staticClass:"right-area"},[_c('div',{staticClass:" flex align-center"},[_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:{'animation-nav':_vm.show==index},on:{"mouseover":function (){
                            _vm.show=index;
                            _vm.click=false
                        },"mouseout":function (){
                            _vm.show=-1;
                        },"click":function($event){return _vm.navChange(item,index)}}},[_c('div',{staticClass:"pos-real"},[_vm._v(" "+_vm._s(item.title)+" "),(_vm.currentIndex===index)?_c('div',{staticClass:"active_border"}):_vm._e()]),_c('product-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.show==index&&item.is_pull),expression:"show==index&&item.is_pull"}],staticClass:"product-component",class:{'prod-nav':_vm.show==index&&item.is_pull},attrs:{"list":_vm.productList},on:{"leave":_vm.leave}})],1)}),_c('a-button',{staticClass:"contact-us",attrs:{"type":"primary"},on:{"click":_vm.contact}},[_vm._v(_vm._s(_vm.$dataConfig.contact))])],2)])])]),_c('div',{staticClass:" small-screen "},[_c('div',{staticClass:"flex flex-between"},[_vm._m(1),_c('div',{staticClass:"collapse-area",on:{"click":_vm.collapseMenu}},[_c('div',{staticClass:"line-icon"},[_vm._v(" |||")]),_c('div',{staticClass:"font12 menu-text"},[_vm._v("MENU")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollpase),expression:"isCollpase"}],staticClass:"collapse-menu-list"},[_c('div',{staticClass:"nav-item close-line"},[_c('div',{staticClass:"close-icon",on:{"click":function($event){_vm.isCollpase=!_vm.isCollpase;_vm.openDevice = false;}}})]),_c('div',{staticClass:"padding-top52"},[_c('div',{staticClass:"nav-item flex  align-center"},[_c('div',{staticClass:"language smno-margin white-color"},[_vm._v(" LANGUAGE ")]),_c('div',{staticClass:"flex  align-center",staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"lang-item link-gray",class:{
                            'lang-active-small':_vm.CurrentLang==1
                        },staticStyle:{"margin-right":"12px"},on:{"click":function($event){return _vm.langSelect(1)}}},[_vm._v(" CN ")]),_c('div',{staticClass:"lang-item link-gray",class:{
                            'lang-active-small':_vm.CurrentLang==2
                        },on:{"click":function($event){return _vm.langSelect(2)}}},[_vm._v(" EN ")])])]),_vm._l((_vm.navList),function(item,index){return [_c('div',{key:index,staticClass:"nav-item flex flex-between",on:{"click":function($event){return _vm.navChangeH5(item,index)}}},[_c('div',{staticClass:"flex flex-between w100 align-center",class:{'nav-line-border ': !_vm.openDevice}},[_c('div',[_vm._v(" "+_vm._s(item.title))]),(item.is_pull)?_c('div',{staticClass:"arrow-icon",class:{
                                'del-icon':_vm.openDevice
                            }}):_vm._e()])]),(item.is_pull&&_vm.openDevice)?_vm._l((item.pull_list),function(elem,i){return _c('div',{key:i+'ch',staticClass:"allgraybg"},[_c('div',{staticClass:"border-bottom-line"},[_c('div',{staticClass:"nav-item",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"border-bottom-line"},[_vm._v(" "+_vm._s(elem.cate_name)+" ")])]),_vm._l((elem.product_list),function(el,n){return _c('div',{key:String(i)+n+'p',staticClass:"nav-item product_title",on:{"click":function($event){return _vm.goProductDetail(el.product_id)}}},[_vm._v(" "+_vm._s(el.product_title)+" ")])})],2)])}):_vm._e()]})],2),_c('div',{staticClass:"nav-item",on:{"click":_vm.contact}},[_vm._v(" "+_vm._s(_vm.$dataConfig.contact)+" ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('i',{staticClass:"logo-img"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-area flex align-center"},[_c('div',{staticClass:"logo"},[_c('i',{staticClass:"logo-img"})])])}]

export { render, staticRenderFns }